import { CART_ITEMS } from './Typecode';

export const getCartItemsfromStorage = async () => {
    let cartItems = [];
    try {
        const stringCartitems = await localStorage.getItem(CART_ITEMS);
        cartItems = stringCartitems ? JSON.parse(stringCartitems) : [];
        return cartItems;
    } catch (error) {
        return cartItems;
    } finally {
        return cartItems;
    }
};
// Increment/decrement localCartItem
export const updateCartItem = async (cartItem, cb) => {
    try {
        const stringCartitems = await localStorage.getItem(CART_ITEMS);
        let items = stringCartitems ? JSON.parse(stringCartitems) : [];
        const updatedCartItems = items.map((item) => {
            if (item.cartItemId === cartItem.cartItemId) {
                item = cartItem;
            }
            return item;
        });
        updateAllCartItem(updatedCartItems);
        if (cb) {
            cb();
        }
    } catch (error) {}
};
// update all local cart items at once
export const updateAllCartItem = async (updatedCartItems, cb) => {
    try {
        let newItems = JSON.stringify(updatedCartItems, getCircularReplacer());
        await localStorage.setItem(CART_ITEMS, newItems);
        if (cb) cb();
    } catch (error) {}
};

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const removeLocalCartItem = async (cartItemId, cb) => {
    try {
        let stringCartitems = await localStorage.getItem(CART_ITEMS);
        stringCartitems = stringCartitems ? JSON.parse(stringCartitems) : [];
        const newItems = stringCartitems?.filter(
            (item) => item.cartItemId !== cartItemId
        );
        await localStorage.setItem(CART_ITEMS, JSON.stringify(newItems));
        if (cb) {
            cb();
        }
    } catch (error) {}
};
export const addLocalCartItem = async (menuItem, cb) => {
    try {
        // let items = getCartItemsfromStorage();
        const stringCartitems = await localStorage.getItem(CART_ITEMS);
        let items = stringCartitems ? JSON.parse(stringCartitems) : [];
        let newItems = [...items, menuItem];
        await localStorage.setItem(CART_ITEMS, JSON.stringify(newItems));
        if (cb) {
            cb();
        }
    } catch (error) {
    } finally {
    }
};

// Add timestamp to the current item to keep track when was the item added to cart
export const generateCartItem = (item) => {
    const cartItem = JSON.parse(JSON.stringify(item, getCircularReplacer()));
    delete cartItem.cartItems;
    cartItem['cart_timestamp'] = new Date().toISOString();
    // contains no. of cart item
    cartItem['cart_item_count'] = 1;
    cartItem['cartItemId'] = Math.random();
    return cartItem;
};
const updateAddonCount = (item) => {
    // Add addon and subAddon count = 0 for all available addon inside menuItem
    const updateCount = (addOns) => {
        for (const addOn of addOns) {
            addOn.count = 0;
        }
    };
    // adding sub-addon category and without sub-addon items in a single place
    item.sub_addons?.forEach((subAdd) => {
        updateCount(subAdd.addons);
    });
    updateCount(item.without_subaddon_addons);
};

const populateCartFields = (items, existingCartItems = []) => {
    const populatedItem = items?.map((item) => {
        // updating total selected count
        updateAddonCount(item);
        // new cartItems property would be added in case cart item updated
        item[CART_ITEMS] =
            existingCartItems?.filter((cart) => cart?.id === item.id) || [];
        item['instruction'] = '';
        return item;
    });
    return populatedItem;
};
export const initializeMenuItems = (dataitems = [], existingCartItems) => {
    // Re-building menu-item which has cart in it
    for (const category of dataitems) {
        const menuSubCategoryItems = category.sub_categories;
        const menuWithoutSubCategoryItems = category.without_sub_category_items;
        for (const subCategory of menuSubCategoryItems) {
            let subCategoryItems = subCategory.items;

            // adding cart property in each  item of sub-categoryItems
            // menu item is populated with cart item field
            subCategory.items = populateCartFields(
                subCategoryItems,
                existingCartItems
            );
        }
        // Without sub category items is populated with cart item field
        category.without_sub_category_items = populateCartFields(
            menuWithoutSubCategoryItems,
            existingCartItems
        );
    }
    return dataitems;
};

export const cartItemRequiredProperty = ['cartItem'];
