import React, { useEffect, useState } from 'react';
import { renderCurrency } from '../services/commonHelper';
import { Badge, Button, notification, Skeleton } from 'antd';
import { themeColors } from '../theme';
import { getRepeatOrderDetails } from '../services/apiService';
import { useLanguage } from '../context/LanguageContext';
import { removeData } from '../services/storageService';
import { CART_ITEMS, OUTLET_ID, SERVICETYPE } from '../services/Typecode';
import { getCircularReplacer } from '../services/cartHelper';
import { useNavigate } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';
import { Radio } from 'antd';

const formatLocalItems = (items) => {
    let filteredItem = items.filter((item) => !item.not_available);
    let mapppedItems = filteredItem.map((item) => {
        return {
            ...item,
            sub_addons: item.sub_addons?.map((subaddon) => {
                if (subaddon.not_available) {
                    return {
                        ...subaddon,
                        addons: subaddon?.addons.map((addon) => ({
                            ...addon,
                            quantity: 0,
                            checkedItem: false,
                            count: 0,
                        })),
                    };
                } else {
                    return {
                        ...subaddon,
                        addons: subaddon?.addons?.map((addon) => {
                            if (addon.not_available) {
                                return {
                                    ...addon,
                                    quantity: 0,
                                    checkedItem: false,
                                    count: 0,
                                };
                            } else {
                                return addon;
                            }
                        }),
                    };
                }
            }),
        };
    });
    return mapppedItems;
};
const generateCartItem = (item) => {
    const cartItem = JSON.parse(JSON.stringify(item, getCircularReplacer()));
    delete cartItem.cartItems;
    cartItem['cart_timestamp'] = new Date().toISOString();
    // contains no. of cart item
    cartItem['cart_item_count'] = item.quantity;
    cartItem['cartItemId'] = Math.random();
    const { quantity, ...rest } = cartItem;
    return rest;
};
const formatOrderDataItems = (orderData) => {
    const formatedItems = orderData.map((data) => {
        const res = generateCartItem(data);
        return res;
    });
    formatedItems.forEach((item) => {
        item.sub_addons?.forEach((customization) => {
            customization.addons?.forEach((addon) => {
                if (addon.quantity > 0) {
                    addon['checkedItem'] = true;
                    addon['count'] = addon.quantity;
                } else {
                    addon['checkedItem'] = false;
                    addon['count'] = 0;
                }
            });
        });
    });
    return formatedItems;
};
const RepeatLastOrder = ({ setRepeatOrderPopupOpen, orderID, serviceType }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const { lang, translation } = useLanguage();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [newServiceType, setNewServiceType] = useState(serviceType);

    useEffect(() => {
        if (orderID) {
            fetchRepeatOrderDetails(orderID);
        }
    }, [orderID]);

    const fetchRepeatOrderDetails = async (id) => {
        try {
            setLoading(true);
            const response = await getRepeatOrderDetails(id, lang);
            if (response.status === 200) {
                const data = response.data || [];
                const formatedOrderData = formatOrderDataItems(data);
                setOrderData(formatedOrderData);
            }
        } catch (error) {
            setError('Something went wrong! Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    const continueClickHandler = () => {
        const exisingServiceType = localStorage.getItem(SERVICETYPE);
        // exisingServiceType !== serviceType
        if (exisingServiceType) {
            removeData(CART_ITEMS);
        }
        const formatedItems = formatLocalItems(orderData);
        if (formatedItems.length > 0) {
            localStorage.setItem(SERVICETYPE, newServiceType);
            let outletID = formatedItems[0].outlet_id || '';
            // menus = menus.filter((item) => Boolean(item.enabled));
            if(outletID){
                localStorage.setItem(OUTLET_ID, outletID);
            }
            localStorage.setItem(CART_ITEMS, JSON.stringify(formatedItems));
            navigate('/checkout');
        } else {
            api.open({
                description:
                    'Sorry! You can not place order as these items is no longer available now. Kindly explore our menu!',
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
        }
    };
    const onChange = (e) => {
        setNewServiceType(e.target.value);
    };
    return (
        <>
            {contextHolder}
            <div className='flex flex-col justify-start gap-4 pb-12'>
                <div className='main-content'>
                    <div className='text-sm text-gray-700 font-semibold'>
                        {translation['repeat_order_title']}
                    </div>
                    <div className=' text-gray-500 text-xs mb-4'>
                        <b> {translation['note']}: </b>{' '}
                        {translation['repeat_order_subtitle']}{' '}
                        {/* {translation['repeat_order_subtitle_pre']}{' '}
                        <b>{serviceType === 'takeaway'
                            ? translation['takeaway_repeatLastOrder']
                            : translation['delivery_repeatLastOrder']}</b>{' '}
                        {translation['repeat_order_subtitle_post']}{' '}
                        <b>
                            {serviceType === 'takeaway'
                                ? translation['delivery_repeatLastOrder']
                                : translation['takeaway_repeatLastOrder']}
                        </b>{' '}
                        {translation['repeat_order_subtitle_last']} */}
                    </div>
                    <div className='flex mb-8 flex-col '>
                        <p className='mb-2'>{translation['deliveryType']}</p>
                        <Radio.Group onChange={onChange} value={newServiceType}>
                            <Radio value={'delivery'}>{translation['delivery']}</Radio>
                            <Radio value={'takeaway'}>{translation['takeaway']}</Radio>
                        </Radio.Group>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        {loading ? (
                            <div className='flex flex-col flex-1 gap-2 justify-start items-center my-4 px-4'>
                                <Skeleton />
                            </div>
                        ) : error ? (
                            <div className='flex flex-col flex-1 gap-2 justify-center items-center  my-4 px-4'>
                                <div
                                    className='text-xs text-center '
                                    style={{ color: 'red' }}
                                >
                                    {translation['something_went_wrong']}
                                </div>
                            </div>
                        ) : (
                            orderData?.map((orderItem, index) => (
                                <div
                                    className='flex flex-row flex-1 justify-between pl-0'
                                    key={orderItem.id}
                                >
                                    <div className='flex flex-1 flex-col justify-start items-start mr-10 gap-2'>
                                        <div className='flex flex-1 flex-row justify-start items-start mr-10'>
                                            <div
                                                className='flex rounded-full justify-center items-center mr-2 w-5 h-5 text-xs'
                                                style={{
                                                    borderWidth: 1,
                                                    minWidth: '1.25rem',
                                                    borderColor:
                                                        themeColors.primaryColor,
                                                }}
                                            >
                                                {index + 1}
                                            </div>
                                            {/* <div className=' flex flex-1 flex-wrap'> */}
                                            <div className=' text-gray-500 text-xs flex'>
                                                {orderItem.name}{' '}
                                                {Boolean(
                                                    orderItem.not_available
                                                ) && (
                                                    <small>
                                                        <Badge
                                                            status='warning'
                                                            count={
                                                                translation['not_available']
                                                            }
                                                        />
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <ul className='flex flex-1 flex-col justify-start items-start pl-8 gap-1'>
                                            {orderItem.sub_addons?.length > 0 &&
                                                orderItem.sub_addons?.map(
                                                    (add) => (
                                                        <li
                                                            className='addon__item list-disc flex flex-col gap-2'
                                                            key={add.id}
                                                        >
                                                            <div className=' text-gray-500 text-xs font-semibold flex'>
                                                                {add.name}{' '}
                                                                {Boolean(
                                                                    add.not_available
                                                                ) && (
                                                                    <Badge
                                                                        status='warning'
                                                                        count={
                                                                            translation['not_available']
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            {add.addons
                                                                .filter(
                                                                    (item) =>
                                                                        item.count >
                                                                        0
                                                                )
                                                                .map(
                                                                    (
                                                                        customization
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                className=' text-gray-500 text-xs pl-3'
                                                                                key={
                                                                                    customization.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    customization.name
                                                                                }{' '}
                                                                                {customization.price >
                                                                                    0 &&
                                                                                    `: ${
                                                                                        customization.count
                                                                                    } * ${renderCurrency(
                                                                                        customization.price
                                                                                    )}`}{' '}
                                                                                    {Boolean(
                                                                                        customization.not_available
                                                                                    ) && (
                                                                                        <Badge
                                                                                            status='warning'
                                                                                            count={
                                                                                                translation['not_available']
                                                                                            }
                                                                                        />
                                                                                    )}    
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                    {orderItem.price > 0 && (
                                        <div className='text-gray-500 text-xs'>
                                            {`${orderItem.cart_item_count} * `}
                                            {renderCurrency(orderItem.price)}
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div
                div
                className='flex justify-center items-center gap-2 mt-auto absolute bg-slate-100 p-2 py-3 w-full left-0 bottom-0'
            >
                <Button onClick={() => setRepeatOrderPopupOpen(false)}>
                    {translation['cancel']}
                </Button>
                <Button
                    type='primary'
                    onClick={() => continueClickHandler()}
                    style={{ backgroundColor: themeColors.primaryColor }}
                >
                    {translation['accept_and_continue']}
                </Button>
            </div>
        </>
    );
};

export default RepeatLastOrder;
