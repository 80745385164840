import React from 'react';
import { themeColors } from '../theme';
import { renderCurrency } from '../services/commonHelper';
import HTMLContent from './HTMLContent';
import { useLanguage } from '../context/LanguageContext';
import { PlusOutlined } from '@ant-design/icons';
import W2GO from '../assets/W2GO.png';
import Counter from './Counter';

const COCONUT = require('../assets/icons/coconut.png');
const EGG = require('../assets/icons/egg.png');
const GLUTEN = require('../assets/icons/gluten.png');
const MILK = require('../assets/icons/milk.png');
const NUTS = require('../assets/icons/nuts.png');
const SOYA = require('../assets/icons/soya.png');
const TREENUTS = require('../assets/icons/treenuts.png');
const SHELFISH = require('../assets/icons/shellfish.png');
const VEGAN = require('../assets/icons/vegan.png');
const VEGETARIAN = require('../assets/icons/vegetarian.png');
const imgList = {
    coconut: COCONUT,
    egg: EGG,
    gluten: GLUTEN,
    milk: MILK,
    nuts: NUTS,
    soya: SOYA,
    treenuts: TREENUTS,
    shellfish: SHELFISH,
    vegetarian: VEGETARIAN,
    vegan: VEGAN,
};
const ItemCard = (props) => {
    const { item, updateItemQuantity, isViewOnly } = props;
    const { translation } = useLanguage();

    return (
        <div
            className={
                'flex bg-white rounded-3xl mb-4 flex-1 shadow border border-gray-200 flex-row-reverse p-2 pb-3 gap-2 w-full'
            }
        >
            <div className='relative flex flex-col justify-start w-24 items-center'>
                {item.thumbnail ? (
                    <img
                        className={'rounded-2xl w-24 h-24 object-cover'}
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${item.thumbnail}`}
                        alt='item'
                    />
                ) : (
                    <img
                        className={'rounded-2xl w-24 h-24 object-cover'}
                        src={W2GO}
                        alt='item'
                    />
                )}
                {!isViewOnly && (
                    <>
                        {item?.cartItems?.length > 0 ? (
                            <Counter
                                decrease={() =>
                                    updateItemQuantity(item, 'decrement')
                                }
                                increase={() =>
                                    updateItemQuantity(item, 'increment')
                                }
                                count={item.cartItems?.reduce(
                                    (acc, item) =>
                                        (acc = acc + item.cart_item_count),
                                    0
                                )}
                            />
                        ) : (
                            <div
                                className='flex flex-row py-1.5 px-3 rounded-full shadow mt-1 justify-center items-center'
                                style={{
                                    backgroundColor: themeColors.primaryColor,
                                }}
                                onClick={() => {
                                    updateItemQuantity(item, 'increment');
                                }}
                            >
                                <PlusOutlined style={{ color: '#fff' }} />
                                <div
                                    className='text-xs font-normal ml-0.5'
                                    style={{
                                        color: '#fff',
                                    }}
                                >
                                    {translation['add']}
                                </div>
                            </div>
                        )}
                        {item.sub_addons?.length > 0 ? (
                            <div
                                className='text-black text-xs text-center '
                                style={{ fontSize: 9 }}
                            >
                                {translation['customizable']}
                            </div>
                        ) : null}
                    </>
                )}
            </div>
            <div
                className={
                    'w-full flex flex-col justify-start flex-1 ml-1 pt-0.5'
                }
            >
                <div className='text-sm font-normal mb-1'>{item.name}</div>
                {item.description && (
                    <div className='text-xs text-gray-700 leading-4'>
                        <HTMLContent html={item.description} />
                    </div>
                )}

                {item.allergens.length > 0 && (
                    <div className='flex flex-row flex-wrap gap-1 mt-3'>
                        <RenderAllergens allergens={item.allergens} />
                    </div>
                )}
                {item.price > 0 && (
                    <div className='flex-row w-full justify-between  mt-auto'>
                        <div className='text-xs font-bold'>
                            {renderCurrency(item.price)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(ItemCard);

const RenderAllergens = ({ allergens = [] }) => {
    return allergens.map((tag) => (
        <div className={`text-black rounded-lg mr-1 ${tag.icon} `} key={tag.id}>
            <img
                style={{
                    width: 25,
                    height: 25,
                    objectFit: 'contain',
                }}
                alt='alergens'
                src={imgList[String(tag.icon).toLowerCase()]}
            />
        </div>
    ));
};
