import React from 'react';
import {
    addLocalCartItem,
    generateCartItem,
    updateCartItem,
} from '../services/cartHelper';
import { renderCurrency } from '../services/commonHelper';
import { themeColors } from '../theme';
import HTMLContent from './HTMLContent';
import { Button, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useLanguage } from '../context/LanguageContext';

const AddonComponent = (props) => {
    const {
        selectedMenuItem,
        selectedCartItemID,
        close,
        updateLocalCartItems,
    } = props;
    const [itemAddon, setItemAddon] = React.useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { translation } = useLanguage();

    React.useEffect(() => {
        populateCheckedFields();
    }, [itemAddon]);

    React.useEffect(() => {
        if (selectedCartItemID) {
            // Creating a temp object  for item, if user is modifying already selected item
            const selectedItem = selectedMenuItem.cartItems.filter(
                (item) => item.cartItemId === selectedCartItemID
            );
            let getItemAddon = JSON.parse(JSON.stringify(selectedItem[0]));
            setItemAddon(getItemAddon);
        } else {
            // Creating a temp Obj for new item
            let getItemAddon = generateCartItem(selectedMenuItem);
            setItemAddon(getItemAddon);
        }
    }, [selectedMenuItem, selectedCartItemID]);

    // get deepcopy of itemaddons
    function getDeepCopy() {
        return JSON.parse(JSON.stringify(itemAddon));
    }

    // add checked item property if count is > 1
    function populateCheckedFields() {
        const populateRadioItem = (subAdd) => {
            let selectedItemId = false;
            subAdd?.addons?.forEach((addon) => {
                if (addon.count > 0) {
                    selectedItemId = addon.id;
                }
            });
            subAdd.checkedItem = selectedItemId;
        };

        const populateCheckBoxAddon = (addons) => {
            addons?.forEach((addon) => {
                addon.isChecked = addon.count > 0;
            });
        };

        const subAddons = itemAddon?.sub_addons;
        subAddons?.forEach((subAdd) => {
            if (subAdd.type === 'multi_select') {
                populateCheckBoxAddon(subAdd.addons);
            } else {
                populateRadioItem(subAdd);
            }
        });
        const withoutSubAddons = itemAddon?.without_subaddon_addons;
        populateCheckBoxAddon(withoutSubAddons);
    }

    // handle checkbox with extra addon
    const handleMultiSelect = async (checked, item) => {
        let itemdata = getDeepCopy();
        if (checked) {
            // mark cliked item as checked
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        addon.isChecked = checked;
                        addon.count = 1;
                    }
                }
            });
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        if (addon.isChecked) {
                            if (max_addon_per_order < addons.length) {
                                //    Only allow to select checkbox if selected items doesn't exceed max item
                                const selectedItem = addons?.filter(
                                    (_item) => _item.isChecked
                                ).length;
                                if (selectedItem > max_addon_per_order) {
                                    const msg = `Max ${max_addon_per_order} can be selected `;
                                    addon.isChecked = false;
                                    addon.count = 0;
                                    return showToastMsg(msg);
                                }
                            }
                        }
                    }
                }
            });
        } else {
            // when user in unchecking the checkbox
            itemdata.sub_addons.forEach((subAddOn) => {
                const { max_addon_per_order, addons } = subAddOn;
                for (const addon of addons) {
                    if (addon.id == item.id) {
                        addon.isChecked = false;
                        addon.count = 0;
                    }
                }
            });
        }
        setItemAddon(itemdata);
    };

    async function showToastMsg(msg) {
        api.open({
            description: msg,
            icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
        });
    }
    // increase qty
    // function onItemAdd(item) {
    //     let itemData = getDeepCopy(itemAddon);
    //     itemData.sub_addons.find((subaddon) => {
    //         for (const addon of subaddon.addons) {
    //             if (addon.id == item.id) {
    //                 if (addon.count < addon.max_per_order) {
    //                     addon.count += 1;
    //                 } else {
    //                     const msg = `Max. ${addon.max_per_order} items allowed.`;
    //                     showToastMsg(msg);
    //                 }
    //             }
    //         }
    //     });
    //     setItemAddon(itemData);
    // }

    // function onItemRemove(item) {
    //     let itemData = getDeepCopy(itemAddon);
    //     itemData.sub_addons.find((subaddon) => {
    //         for (const addon of subaddon.addons) {
    //             if (addon.id == item.id) {
    //                 if (addon.count > 1) {
    //                     addon.count -= 1;
    //                 } else {
    //                     const msg = `Min. 1 item allowed.`;
    //                     showToastMsg(msg);
    //                 }
    //             }
    //         }
    //     });
    //     setItemAddon(itemData);
    // }

    function selectedRadioAddon(selectedItemId, sub) {
        let itemAddonCopy = getDeepCopy();
        itemAddonCopy.sub_addons.find((subaddon) => {
            if (subaddon.id == sub.id) {
                for (const addon of subaddon.addons) {
                    if (addon.id == selectedItemId) {
                        addon.count = 1;
                        addon.checkedItem = true;
                    } else {
                        addon.count = 0;
                        addon.checkedItem = false;
                    }
                }
            }
        });
        setItemAddon(itemAddonCopy);
    }

    function selectedCheckBoxAddon(checked, item) {
        handleMultiSelect(checked, item);
    }

    function getSelectedCount() {
        let count = 0;

        const updateCount = (addons) => {
            addons.forEach((addon) => {
                count += addon.count;
            });
        };

        itemAddon.sub_addons.forEach((subAdd) => {
            updateCount(subAdd.addons);
        });
        updateCount(itemAddon.without_subaddon_addons);

        return count;
    }

    function Addonsvalidation() {
        let AllAddons = [
            ...itemAddon?.sub_addons?.filter((item) => item.enabled === 1),
            ...itemAddon?.without_subaddon_addons?.filter(
                (item) => item.enabled === 1
            ),
        ];
        let totalAddonsCount = AllAddons.length;
        let tempCount = 0;
        for (let i = 0; i < AllAddons.length; i++) {
            const { addons } = AllAddons[i];
            for (let j = 0; j < addons?.length; j++) {
                const element = addons[j];
                if (element?.count) {
                    tempCount += 1;
                    break;
                }
            }
        }
        return totalAddonsCount === tempCount;
    }
    async function addAddOns() {
        // const isAllselected = Addonsvalidation();

        const count = getSelectedCount();

        if (count) {
            //    Item added to cart then dismiss the modal
            let operationType = '';

            if (selectedCartItemID) {
                const updatedCartselectedMenuItems =
                    selectedMenuItem.cartItems.map((item) => {
                        if (item.cartItemId === selectedCartItemID) {
                            item = itemAddon;
                        }
                        return item;
                    });
                selectedMenuItem.cartItems = updatedCartselectedMenuItems;
                updateCartItem(itemAddon, () => updateLocalCartItems());
                operationType = 'update';
            } else {
                // If item is newly added
                selectedMenuItem.cartItems.push(itemAddon);
                addLocalCartItem(itemAddon, () => updateLocalCartItems());
                operationType = 'add';
            }
            // updateOperationType(operationType);
            close();
        } else {
            await showToastMsg(
                'Kindly select at least one option to proceed further.'
            );
            // return;
        }
    }
    return (
        <div className='flex flex-col'>
            {contextHolder}
            <div className=' flex flex-col rounded-lg overflow-hidden'>
                {selectedMenuItem.thumbnail && (
                    <img
                        className='w-full h-40'
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${selectedMenuItem.thumbnail}`}
                        alt='menu name'
                    />
                )}
                <div
                    className='px-3 py-3 flex flex-col bg-white text-md font-medium'
                    style={{ backgroundColor: '#f2f6fc' }}
                >
                    {itemAddon.name}
                </div>
            </div>

            {itemAddon?.sub_addons?.map((subAddon) =>
                subAddon.enabled ? (
                    <div className='addon-main mt-2' key={subAddon.id}>
                        {subAddon.type === 'single_select' ? (
                            <div className='flex flex-col'>
                                <div
                                    className='text-md font-medium'
                                    style={{ color: '#535665' }}
                                >
                                    {subAddon.name}
                                </div>
                                {subAddon.description ? (
                                    <div
                                        className=' text-gray-500 mt-2 text-xs'
                                        dangerouslySetInnerHTML={{
                                            __html: subAddon.description,
                                        }}
                                    ></div>
                                ) : null}
                                <div
                                    className='flex mt-4 flex-col'
                                    style={{
                                        borderTop: '1px solid #ebebeb',
                                    }}
                                >
                                    {subAddon?.addons.map((item) =>
                                        item.enabled ? (
                                            <div
                                                className='flex flex-row justify-start flex-1 w-full py-3 px-2 gap-2'
                                                style={{
                                                    borderBottom:
                                                        '1px solid #ebebeb',
                                                }}
                                                key={item.id}
                                            >
                                                <input
                                                    type='radio'
                                                    id={item.id}
                                                    name={subAddon.id}
                                                    value={item.id}
                                                    onChange={(e) =>
                                                        selectedRadioAddon(
                                                            item.id,
                                                            subAddon
                                                        )
                                                    }
                                                    // checked={subAddon.checkedItem}
                                                />
                                                <label htmlFor={item.id}>
                                                    {item.name}
                                                </label>
                                                {item.price > 0 ? (
                                                    <div className='flex-row justify-between items-center mr-2'>
                                                        <div className='text-xs font-bold'>
                                                            {renderCurrency(
                                                                item.price
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        ) : subAddon.type === 'multi_select' ? (
                            <div className='flex flex-col'>
                                <div
                                    className='text-md font-medium mt-2 mx-4'
                                    style={{ color: '#535665' }}
                                >
                                    {subAddon.name}
                                </div>
                                {subAddon.description ? (
                                    <div className='text-xs text-gray-500 mt-2'>
                                        <HTMLContent
                                            html={subAddon.description}
                                        />
                                    </div>
                                ) : null}
                                <div
                                    className='flex mt-4 '
                                    style={{
                                        backgroundColor: '#f6f8fa',
                                        borderTopWidth: 1,
                                        borderTopColor: '#ebebeb',
                                    }}
                                >
                                    {subAddon.addons.map((item) =>
                                        item.enabled ? (
                                            <div
                                                className='flex flex-row justify-start flex-1 w-full py-3 px-2 gap-2'
                                                style={{
                                                    borderBottom:
                                                        '1px solid #ebebeb',
                                                }}
                                                key={item.id}
                                            >
                                                <input
                                                    type='checkbox'
                                                    id={item.id}
                                                    name={item.name}
                                                    checked={item.isChecked}
                                                    value={item.id}
                                                    onChange={(e) => {
                                                        selectedCheckBoxAddon(
                                                            !item.isChecked,
                                                            item
                                                        );
                                                    }}
                                                />
                                                {item.name}
                                                {item.price > 0 ? (
                                                    <div className='flex-row justify-between items-center mr-2'>
                                                        <div className='text-xs font-bold'>
                                                            {renderCurrency(
                                                                item.price
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )
            )}
            <Button
                style={{ backgroundColor: themeColors.primaryColor }}
                className='flex-row text-center rounded-full shadow-lg h-12 justify-center items-center gap-x-2 mt-3 text-white text-md'
                onClick={addAddOns}
            >
                {translation['add_customisation']}
            </Button>
        </div>
    );
};

export default AddonComponent;
